.flex-container {
  display: flex;
  align-items: baseline;
}

.padding-div {
  padding-bottom: 10px;
}

#warning-text {
  color: red;
}
