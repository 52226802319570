.tomtom-search-map {
    height: 410px;
    width: 100%;
  }
  
  .marker-delivery {
    background-image: url('https://i.imgur.com/cIooqnp.png');
    background-size: cover;
    width: 22px;
    height: 22px;
    border-radius: 15px;
    border: solid darkslategrey 3px;
  }
  
  .tomtom-search-app {
    height: 450px;
  }  