.ant-layout-sider-light .ant-layout-sider-trigger {
  background: #ecf2f5;
}
.expand-detail-promotion {
  font-size: 12px;
}
.expand-detail-promotion .expand-detail-title {
  font-weight: bold;
  font-size: 13px;
  margin-left: -15px;
}
.expand-detail-promotion p {
  line-height: 30px;
  margin-bottom: 0px;
}
.report .ant-table {
  font-size: 12px;
}
.report .ant-form-item-label {
  line-height: 25px;
}
.report .ant-form label,
.report .ant-form-item {
  font-size: 13px;
}
.hide-checkbox-header .ant-table-thead .ant-table-selection-column .ant-checkbox-wrapper,
.table-daily-summary-order
  .status-all
  + .ant-table-expanded-row
  .ant-table-thead
  .ant-table-selection-column
  .ant-checkbox-wrapper,
.table-daily-summary-order .status-all .ant-table-selection-column .ant-checkbox-wrapper,
.tablePos .ant-table-selection-column .ant-checkbox-wrapper,
.tableCustomer_Care .ant-table-selection-column .ant-checkbox-wrapper {
  display: none;
}
.tablePos tr.invoiceStatusshipping .ant-checkbox-wrapper,
.tablePos tr.invoiceStatusshipped .ant-checkbox-wrapper,
.tablePos tr.invoiceStatuscompleted .ant-checkbox-wrapper,
.tableCustomer_Care tr.invoiceStatuspending .ant-checkbox-wrapper {
  display: block;
}
.red {
  color: #f44242;
}
.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}

.table-daily-summary-order .status-all {
  background-color: #9ab4d0c7 !important;
}
.table-daily-summary-order .status-all + .ant-table-expanded-row .ant-table .ant-table-thead tr,
.table-daily-summary-order .status-all:hover td {
  background-color: #94b1d0 !important;
}

.table-activated-row {
  background-color: #e6f5ff !important;
}
