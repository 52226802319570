.map {
    min-height: 650px;
    height: 100%;
    width: 105%;
  }

.tomtom-cvrp-map {
    min-height: 700px;
    height: 100%;
    width: 100%;
  }

  /* .marker {
    background-image: url('https://i.imgur.com/oz3r3Wq.png');
    background-position: center;
    background-size: 22px 22px;
    border-radius: 50%;
    height: 22px;
    left: 4px;
    position: absolute;
    text-align: center;
    top: 3px;
    transform: rotate(45deg);
    width: 22px;
  } */
  .marker-icon {
    /* background-color: white; */
    background-position: center;
    background-size: 22px 22px;
    border-radius: 50%;
    height: 22px;
    left: 4px;
    position: absolute;
    text-align: center;
    top: 3px;
    transform: rotate(45deg);
    width: 22px;
  }
  .marker {
      height: 30px;
      width: 30px;
  }
  .marker-content {
      background: #c30b82;
      border-radius: 50% 50% 50% 0;
      height: 30px;
      left: 50%;
      margin: -15px 0 0 -15px;
      position: absolute;
      top: 50%;
      transform: rotate(-45deg);
      width: 30px;
  }
  .marker-text {
    z-index: 999;
    position: absolute;
    width: 100%;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    margin: 5px 0;
  }
  .marker-text-many {
    z-index: 999;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    margin: 5px 0;
    white-space: nowrap;
    overflow: hidden;
  }
  .marker-content::before {
    background: #ffffff;
    border-radius: 50%;
    content: "";
    height: 24px;
    margin: 3px 0 0 3px;
    position: absolute;
    width: 24px;
}

  .stop {
    background-image: url('https://i.imgur.com/KPO3fR9.png');
    background-size: cover;
    width: 22px;
    height: 22px;
    background-color: #c30b82;
    border-radius: 11px;
    border: solid 3px darkslategray;
  }
  
  .marker-delivery {
    background-image: url('https://i.imgur.com/cIooqnp.png');
    background-size: cover;
    width: 22px;
    height: 22px;
    border-radius: 15px;
    border: solid darkslategrey 3px;
  }
  
  /* .tomtom-cvrp-map {
    width: 100%;
    height: 100%;
  }   */